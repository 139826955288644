import moment from 'moment'
import { isLocalStorage, toMoneyFormat, toLimitedSort, toDecimalsCorrect } from 'utils'
import { toMoneyFormatLong } from 'modules/dashboard/PortfolioAnalysis/PortfolioAnalysisUtils'

import {
  DATE_FORMAT_PORTFOLIO_DEFAULT,
  PORTFOLIO_DATE_FORMATS,
  DISPLAY_FILTER,
  ACCOUNT_TYPES,
  MONEYMADE_MANUAL_ACCOUNT
} from 'static/dashboardConstants'

import {
  getAverageValue,
  getDecimalAdjustmentOfNumber,
  getDifferenceDataCombined,
  getLastValueFromArray
} from 'utils/getters'
import { toSignCorrectDisplayValue } from 'modules/asset/AssetPage/assetPageUtils'

const toSortBy = (items, key) => items.sort((a, b) => (a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0))

export const getAccountDifference = (totalData, accountValue) =>
  toMoneyFormat(getDifferenceDataCombined(totalData, 'balance', accountValue))

// @Ruslan
const getMax = data =>
  // eslint-disable-next-line prefer-spread
  Math.max.apply(
    Math,
    Object.values(data)
      .filter(i => i.sum !== null)
      .map(i => i.sum)
  )
// @Ruslan
const getMin = data =>
  // eslint-disable-next-line prefer-spread
  Math.min.apply(
    Math,
    Object.values(data)
      .filter(i => i.sum !== null)
      .map(i => i.sum)
  )

const toFilterbyAccountType = (portfolioData, type) =>
  toSortBy(
    portfolioData.filter(({ account_type: accountType }) => accountType === type),
    'full_account_name'
  )

const isObjectKeyEmpty = (data, prop) => {
  if (Object.values(data).length > 0) {
    if (data[prop].length > 0) return false
    return true
  }
  return true
}

const isPortfolioResponseSuccess = response => {
  if (!response.success) return false
  if (Object.values(response.message).length === 0) return false
  if (isObjectKeyEmpty(response.result, 'general')) return false
  return true
}

const getAccounts = ({ accounts }) => [...Object.values(accounts)].flat()

const isBalancesResponseSuccess = (success, result) => {
  if (!success || getAccounts(result)?.length === 0) return false
  return true
}

const getConnectedAccounts = totalBalances => totalBalances.filter(({ balance }) => balance !== null)

const isFirstAccountAdded = prevBalance => prevBalance[prevBalance.length - 1] === 0

const getTimeDifference = diffTime => {
  const oneWeek = 1000 * 60 * 60 * 24 * 7
  const oneMonth = 1000 * 60 * 60 * 24 * 30
  const threeMonth = 1000 * 60 * 60 * 24 * 30 * 3
  // const sixMonth = 1000 * 60 * 60 * 24 * 30 * 6
  const oneYear = 1000 * 60 * 60 * 24 * 30 * 12

  if (diffTime <= oneWeek) return '1W'
  if (diffTime > oneWeek && diffTime <= oneMonth) return '1M'
  if (diffTime > oneMonth && diffTime <= threeMonth) return '3M'
  // if (diffTime > threeMonth && diffTime <= sixMonth) return '6M'
  if (diffTime > threeMonth && diffTime <= oneYear) return '1Y'
  if (diffTime > oneYear) return 'ALL'
  return 'ALL'
}

const getBalances = data => data.filter(i => i.balances).length > 0

const toFilterGeneralValue = portfolioPlatforms => {
  if (portfolioPlatforms.length > 0) {
    // get date of the first connected platform
    const lastDate = Math.min(
      ...portfolioPlatforms
        .filter(({ connected_at: connectedAt }) => connectedAt)
        .map(({ connected_at: connectedAt }) => new Date(connectedAt).getTime())
    )
    const nowDate = new Date().getTime()
    const diffTime = nowDate - lastDate
    // set filter according to time when platform was connected
    return getTimeDifference(diffTime)
  }
  return null
}

const toFilterPlatformValue = (i, dateFormatDefault) => {
  if (i?.connected_at) {
    const lastDate = new Date(i?.connected_at).getTime()
    const nowDate = new Date().getTime()
    const diffTime = nowDate - lastDate
    return getTimeDifference(diffTime)
  }
  return dateFormatDefault
}

const getFirstValueFromArray = (ar, partition = 'balance', currentValue = null) => {
  const dataFiltered = Object.values(ar).filter(i => i.balance !== null)
  return !currentValue ? dataFiltered[0][partition] : currentValue
}

const getPercentagePure = (firstValue, lastValue) => {
  const percentAge = ((lastValue - firstValue) / lastValue) * 100
  return Number.isFinite(percentAge) ? percentAge : 0
}

const getDateFormatStepWidth = (format, daily = 'daily') => {
  switch (format) {
    case '1W':
      return daily
    case '1M':
      return daily
    case '3M':
      return daily
    case '6M':
      return daily
    case '1Y':
      return daily
    case 'ALL':
      return daily
    default:
      return daily
  }
}

const getTimeHours = (data, item, portfolio) => {
  if (item !== null && item !== undefined) {
    const dataParsed = data[item.slug]
    const lastValueTimeStamp = getLastValueFromArray(dataParsed, 'date')
    const hoursThen = new Date(lastValueTimeStamp).getHours()
    return hoursThen === 0 ? 24 : hoursThen
  }
  if (portfolio) {
    const lastValueTimeStamp = getLastValueFromArray(Object.values(data)[0], 'date')
    const hoursThen = new Date(lastValueTimeStamp).getHours()
    return hoursThen
  }
  const lastValueTimeStamp = getLastValueFromArray(data, 'date')
  const hoursThen = new Date(lastValueTimeStamp).getHours()
  return hoursThen
}

const getDateFormatDelimeter = format => {
  switch (format) {
    case '1W':
      return 7
    case '1M':
      return 30
    case '3M':
      return 30 * 3
    case '6M':
      return 30 * 6
    case '1Y':
      return 365
    case 'ALL':
      return null
    default:
      return null
  }
}

const toNewPlatformItem = (item, newItemDataParsed) => ({
  ...item,
  data: newItemDataParsed,
  last: getDecimalAdjustmentOfNumber('round', getLastValueFromArray(newItemDataParsed, 'balance'), -2),
  average: getDecimalAdjustmentOfNumber('round', getAverageValue(newItemDataParsed, 'balance'), -2),
  value2: getDifferenceDataCombined(newItemDataParsed, 'balance')
})

const toLimitDataByDelimiter = (data, limit) => {
  if (Array.isArray(data)) return data.slice(-limit)

  const o = {}
  Object.entries(data).forEach(([key, value]) => {
    if (key === undefined) {
      return
    }
    o[key] = value.slice(-limit)
  })
  return o
}

const toDataRawLimited = (dataRaw, filter = DATE_FORMAT_PORTFOLIO_DEFAULT) => {
  const delemiter = getDateFormatDelimeter(filter)
  const dataRawLimited = toLimitDataByDelimiter(dataRaw, delemiter)
  return [dataRawLimited, delemiter]
}

const toReplaceDateProp = (data, format) => {
  if (data.length === 0) return []
  return data.map(i => ({
    ...i,
    date: moment(i.date).format(format)
  }))
}

const getItemFilteredDifference = (filter, dataRaw) => {
  const delemiter = getDateFormatDelimeter(filter)
  const dataRawLimited = toLimitDataByDelimiter(dataRaw, delemiter)
  const newItemDataParsed = toReplaceDateProp(dataRawLimited, PORTFOLIO_DATE_FORMATS[filter])

  return newItemDataParsed
}

const toActualDataDifference = (filter, portfolioDataRaw, dataDifference, lastValue) => {
  const oneDayDataTotalParsed = getItemFilteredDifference(filter, portfolioDataRaw)
  const oneDayfirstValue = getFirstValueFromArray(oneDayDataTotalParsed)
  const oneDaylastValue = getLastValueFromArray(oneDayDataTotalParsed)
  const difference = oneDaylastValue - oneDayfirstValue
  const differencePercentAge = getPercentagePure(oneDayfirstValue, oneDaylastValue)
  return {
    value: difference === dataDifference ? lastValue : difference,
    value2: difference === dataDifference ? 100 : differencePercentAge
  }
}

const isNegativeNumber = value => value.indexOf('-') === 0

const toSignCorrectPercentageValue = value =>
  isNegativeNumber(value) ? `-${value.replace('-', '')}%` : value === '0.00' ? `${value}%` : `+${value}%`

const toObjectWithoutEmptyResult = data => {
  const o = {}
  Object.entries(data).forEach(([key, value]) => {
    if (key === undefined) return
    if (value.length === 0) return
    o[key] = value
  })
  return o
}

const toObjectWithoutProperty = (obj, prop) => {
  const data = { ...obj }
  delete data[prop]
  return data
}

const toSortedObjectByTime = (data, prop) => {
  const o = {}
  Object.entries(data).forEach(([key, value]) => {
    if (key === undefined) return
    o[key] = toSortBy(value, prop)
  })
  return o
}

const toNotConnectedPlatforms = (portfolioPlatforms, dataRawDailySorted) =>
  portfolioPlatforms.filter(
    ({ slug }) => Object.keys(toObjectWithoutProperty(dataRawDailySorted, 'general')).indexOf(slug) === -1
  )

const toFillArrayWithEmptyValues = (timeArray, delemiter) =>
  timeArray.slice(delemiter).map(i => {
    const value = {
      balance: null,
      date: i
    }
    return value
  })

const toFilteredMMplatforms = (mm, selected, filter) => {
  // filter - true: MM only, filter -fale: not MM platforms
  const ar = []
  selected.forEach(s => {
    if (filter) {
      if (mm.filter(data => data.slug.toLowerCase() === s.slug.toLowerCase())?.length > 0) ar.push(s)
    } else if (mm.filter(data => data.slug.toLowerCase() === s.slug.toLowerCase())?.length === 0) ar.push(s)
  })
  return ar
}

const toInitialDataAnchors = (ar, o = {}) => {
  ar.forEach(i => {
    // @Ruslan  o, change on normal name
    // eslint-disable-next-line no-param-reassign
    o[i.slug] = null
  })
  return o
}

const toReplaceTimestampProp = (data, format) => {
  if (data.length === 0) return []
  return data.map(i => ({
    ...i,
    date: moment(i.timestamp).format(format)
  }))
}

const getTooltipValue = payload => (payload?.length > 0 ? payload[0].value : 0)

const getTagsFromArray = portfolioData =>
  portfolioData
    .filter(i => i.connector === 'manual' && i?.twitter)
    .map(el => `@${el.twitter}`)
    .join(', ')

const toFilteredData = (totalDataRaw, filter) => {
  // parse data according to correct filter value
  const [dataRawLimited] = toDataRawLimited(totalDataRaw, filter)
  // format timestamp prop according to filter value
  const dataTotalParsed = toReplaceTimestampProp(dataRawLimited, PORTFOLIO_DATE_FORMATS[filter])
  return dataTotalParsed
}

const toPercentAge = (value, balance) => `${getDecimalAdjustmentOfNumber('round', (100 * value) / balance, -2) || 0}%`

const toGroupedItems = (portfolioData, investments) => {
  // init data
  const ar = []
  let cat = ''

  // sort by category
  const sorted = toSortBy(
    portfolioData
      .filter(i => i.total !== null && i.total !== undefined && i.total !== '')
      .map(portfolio => ({
        ...portfolio,
        category: portfolio?.type
          ? portfolio?.type
          : investments.find(({ slug }) => slug === portfolio.slug)
          ? investments.find(({ slug }) => slug === portfolio.slug).industry
          : 'Other investments'
      })),
    'category'
  )
  // combine 'investments' and 'other investments'
  const sortedByOtherInvestments = [
    ...sorted.filter(i => i.category !== 'Other investments'),
    ...sorted.filter(i => i.category === 'Other investments')
  ]
  // generate array of objects grouped by category and count
  sortedByOtherInvestments.forEach(a => {
    if (a.category !== cat) {
      ar.push({
        [a.category]: a.total,
        count: 1
      })
      cat = a.category
    } else if (a.category === cat) {
      const key = Object.keys(ar[ar.length - 1])[0]
      ar[ar.length - 1][key] += a.total
      ar[ar.length - 1].count += +1
    }
  })

  // convert it to recharts data format
  const data = ar.map((i, index) => ({
    name: Object.keys(ar[index])[0],
    value: Object.values(ar[index])[0],
    count: i.count
  }))
  return [data]
}

const getSumObjectProp = (data, prop) => {
  let s = 0
  data.forEach(i => {
    s += i[prop]
  })
  return s
}

const getInvestmentsPercentAge = (investments, balance) =>
  investments.map(i => ({
    name: i.name,
    lastValue: i.lastValue,
    percentAge: `${getDecimalAdjustmentOfNumber('round', (i.lastValue * 100) / balance, -2) || 0}%`,
    full_account_name: i.full_account_name
  }))

const getInvestmentsGeneral = (general, balances, period) => {
  const ar = []
  general[period].forEach(g => {
    const { date } = g

    let balance = 0

    // @Ruslan _
    // eslint-disable-next-line no-unused-vars
    Object.entries(balances[period]).forEach(([_, v]) => {
      const o = v.find(j => j.date === date)
      if (o) {
        balance += o.balance
      }
    })

    ar.push({
      balance,
      date,
      accountId: 'investments_general'
    })
  })

  return ar
}

const getInvestmentsMM = (investments, balances, period) => {
  const o = {}
  Object.entries(balances[period])
    .filter(([k]) => investments.find(({ slug }) => slug === k))
    .forEach(([key, value]) => {
      o[key] = value
    })
  return o
}

const toCorrectData = (portfolioPlatforms, investments) =>
  portfolioPlatforms.reduce((acc, investment) => {
    acc.push({
      ...investment,
      logo: investments.find(({ slug }) => slug === investment.slug)?.logo || investment?.logo || '',
      id: investment?.platform_id || ''
    })
    return acc
  }, [])

const toLastUpdatedTime = time => (time ? moment(time).format('MMM DD, YYYY') : '')

const toLastUpdatedTimeDisplaying = (hoursDifference, currentDate, updatedAtDate) => {
  const difference = currentDate - updatedAtDate
  // less than 1 hour
  if (hoursDifference < 1) return `${(difference / 1000 / 60).toFixed(0)} min ago`
  // less than 1 day
  if (hoursDifference >= 1 && hoursDifference < 24) return `${(difference / 1000 / 60 / 60).toFixed(0)} hours ago`
  // less than 2 weeks
  if (hoursDifference >= 24 && hoursDifference < 24 * 14)
    return `${(difference / 1000 / 60 / 60 / 24).toFixed(0)} days ago`
  // less than 1 year
  if (hoursDifference >= 24 * 14 && hoursDifference < 24 * 365) return moment(updatedAtDate).format('MMM D')
  // more than 1 year
  return moment(updatedAtDate).format('MM/DD/YY')
}

const toCorrectValue = value => `$${toMoneyFormat(value.toFixed(2) || 0)}`

const toCorrectValueNoCents = (value, decimal = 2) =>
  `$${toMoneyFormat(getDecimalAdjustmentOfNumber('round', value?.toFixed(decimal) || 0, -2), 0)}`

const toCorrectValueCents = (value, decimal = 2) => `$${toMoneyFormat(value?.toFixed(decimal) || 0)}`

const toCorrectValueNoCentsYTD = (value, decimal = 1) => {
  const dataCommas = toMoneyFormat(value?.toFixed(decimal) || 0)
  const data = getDecimalAdjustmentOfNumber('round', value?.toFixed(decimal) || 0, -2)
  return `${data === 0 ? dataCommas : data > 0 ? `+${dataCommas}` : `${dataCommas}`}%`
}

const toPortfolioSections = (portfolioData, totalBalances) => [
  {
    header: ACCOUNT_TYPES.INVESTMENT,
    portfolioData: toFilterbyAccountType(portfolioData, ACCOUNT_TYPES.INVESTMENT),
    showGraph: false,
    totalBalance: totalBalances.INVESTMENT
  },
  {
    header: ACCOUNT_TYPES.CASH,
    portfolioData: toFilterbyAccountType(portfolioData, ACCOUNT_TYPES.CASH),
    showGraph: false,
    totalBalance: totalBalances.CASH
  },
  {
    header: ACCOUNT_TYPES.OTHER,
    portfolioData: toFilterbyAccountType(portfolioData, ACCOUNT_TYPES.OTHER),
    showGraph: false,
    totalBalance: totalBalances.OTHER
  }
]

const toSelectedCheckboxesObject = (objectResult, objectFilter) => {
  const obj = {}
  objectResult.forEach(i => {
    const selected = objectFilter.filter(({ slug }) => slug === i.slug).length > 0
    obj[i.slug] = selected
  })

  return obj
}

const toUniqueObjects = objectArray =>
  objectArray.reduce((accumulator, current) => {
    if (!accumulator.some(x => x.id === current.id)) {
      accumulator.push(current)
    }
    return accumulator
  }, [])

const toUpdatedObjectByChecked = (checked, p, obj) =>
  checked
    ? toUniqueObjects([
        ...obj,
        {
          id: p.id,
          slug: p.slug,
          name: p.name,
          logo: p.logo,
          label: p.name,
          key: p.id
        }
      ])
    : obj.filter(i => i.name !== p.name)

const getAllAccountsInit = (allAccounts, obj = {}) => {
  allAccounts.forEach(i => {
    // @Ruslan
    // eslint-disable-next-line no-param-reassign
    obj[i.slug] = false
  })

  return obj
}

const toManualDataInit = (manualAccountSchemas, select) =>
  Object.entries(manualAccountSchemas.find(({ account_type: accountType }) => accountType === select)?.schema)

const getManualDataKeys = (manualAccountSchemas = [], select = '') =>
  Object.keys(manualAccountSchemas.find(({ account_type: accountType }) => accountType === select)?.schema).reduce(
    (ac, item) => {
      // @Ruslan
      // eslint-disable-next-line no-param-reassign
      ac[item] = ''
      return ac
    },
    {}
  )

const getRequierdKeys = (manualAccountSchemas, select) =>
  Object.entries(manualAccountSchemas.find(({ account_type: accountType }) => accountType === select)?.schema).reduce(
    (ac, [key, value]) => {
      // @Ruslan
      // eslint-disable-next-line no-param-reassign
      if (value.required) ac[key] = value.required
      return ac
    },
    {}
  )

const isAllowed = (required, data) =>
  Object.keys(required).reduce((ac, key) => {
    if (data[key]) ac.push(true)
    return ac
  }, []).length === Object.keys(required).length

//  @Ruslan a,k,v, change on normal name
// eslint-disable-next-line no-return-assign, no-param-reassign
const clean = obj => Object.entries(obj).reduce((a, [k, v]) => (!v ? a : ((a[k] = v), a)), {})

const toShapeData = manualAccount => {
  if (manualAccount?.type === 'P2P Loan') {
    const o = { ...manualAccount }
    // convert to ISO date
    o.data = {
      ...o.data,
      loan_date: o.data?.loan_date ? new Date(o.data?.loan_date).toISOString() : o.data?.loan_date,
      estimated_return_date: o.data?.estimated_return_date
        ? new Date(o.data?.estimated_return_date).toISOString()
        : o.data?.estimated_return_date
    }
    // remove empty props
    o.data = clean(o.data)
    return o
  }
  const o = { ...manualAccount }
  // remove empty props
  o.data = clean(o.data)
  return o
}

const getPlatformIdsCount = (allPlatforms, platformId, accountType) =>
  allPlatforms.filter(
    ({ platform_id: IdPlatform, account_type: typeAcount }) => IdPlatform === platformId && typeAcount === accountType
  ).length

const getRate = balances => {
  if (balances instanceof Array && balances?.length > 1) {
    const firstValue = balances[(balances?.length || 0) - 2]?.balance
    const lastValue = balances[(balances?.length || 0) - 1]?.balance
    const balanceRate = lastValue - firstValue
    const dataDifferenceRecentAge = toSignCorrectPercentageValue(
      toDecimalsCorrect(getPercentagePure(firstValue, lastValue) || 0)
    )
    return `${toSignCorrectDisplayValue(toDecimalsCorrect(balanceRate)) || `$0,00`} (${dataDifferenceRecentAge})`
  }
  return `$0,00 (0%)`
}

const isLastId = (data, index, platformId) =>
  data
    .slice(index)
    .filter(({ provider_name: providerName }) => providerName !== MONEYMADE_MANUAL_ACCOUNT)
    .filter(({ platform_id: idPlatform }) => idPlatform === platformId)?.length === 1

// prepare portfolio data to displaying
const toPortfolioPlatforms = (investments, totalBalances, portfolioPlatforms) => {
  // init accounts array
  const accounts = []
  // sort through an dataRawLimited array to get needed data
  portfolioPlatforms.forEach((value, index) => {
    // get values from selected paltform
    const platformSelected = value

    // set date format according to the last date when current platform was connected
    let filter = ''
    if (platformSelected?.connected_at) {
      const lastDate = new Date(platformSelected?.connected_at).getTime()
      const nowDate = new Date().getTime()
      const diffTime = nowDate - lastDate
      filter = getTimeDifference(diffTime)
    }
    // set data according to filter value
    const currentdataRaw = platformSelected?.balances || []
    const delemiter = getDateFormatDelimeter(filter)
    const dataRawLimitedObj = toLimitDataByDelimiter(currentdataRaw, delemiter)

    const allDataDifference = {
      sinceDate: '',
      value: '',
      value2: '',
      oneDay: '',
      oneWeek: '',
      oneMonth: '',
      threeMonth: '',
      oneYear: ''
    }
    let firstValue = 0
    let lastValue = 0

    if (dataRawLimitedObj.length > 0) {
      // get since date
      const firstTime = moment(getFirstValueFromArray(dataRawLimitedObj, 'date')).format('MMM YYYY')
      // All
      firstValue = getFirstValueFromArray(dataRawLimitedObj)
      lastValue = platformSelected?.balance
      const dataDifference = lastValue - firstValue
      const dataDifferenceRecentAge = getPercentagePure(firstValue, lastValue)
      // collect all data related to difference in one place
      allDataDifference.sinceDate = firstTime
      allDataDifference.value = dataDifference
      allDataDifference.value2 = dataDifferenceRecentAge || 0
      allDataDifference.oneDay = toActualDataDifference('1D', dataRawLimitedObj, dataDifference, lastValue)
      allDataDifference.oneWeek = toActualDataDifference('1W', dataRawLimitedObj, dataDifference, lastValue)
      allDataDifference.oneMonth = toActualDataDifference('1M', dataRawLimitedObj, dataDifference, lastValue)
      allDataDifference.threeMonth = toActualDataDifference('3M', dataRawLimitedObj, dataDifference, lastValue)
      allDataDifference.oneYear = toActualDataDifference('1Y', dataRawLimitedObj, dataDifference, lastValue)
    }

    // generate current year data for YTD
    const ytdData =
      platformSelected?.balances?.filter(({ date }) => date >= new Date(new Date().getFullYear(), 0, 1).getTime()) || []

    // add each item object to an array
    accounts.push({
      firstValue,
      lastValue,
      data: platformSelected?.balances,
      last: platformSelected?.balance || 0,
      value: toCorrectValue(platformSelected?.balance || 0),
      total: platformSelected?.balance || 0,
      allocated: ((platformSelected.balance * 100) / (totalBalances.GENERAL / 100)).toFixed(2),
      logo: investments.find(({ slug }) => slug === platformSelected?.slug)?.logo || platformSelected?.logo || '',
      name: platformSelected?.name || '',
      account_type: platformSelected?.account_type || ACCOUNT_TYPES.INVESTMENT,
      provider_name: platformSelected?.provider_name || '',
      provider_account_name: platformSelected?.provider_account_name || '',
      full_account_name:
        platformSelected.provider_name === MONEYMADE_MANUAL_ACCOUNT
          ? platformSelected?.additional_data?.name || platformSelected.full_account_name
          : getPlatformIdsCount(portfolioPlatforms, platformSelected?.platform_id, platformSelected?.account_type) === 1
          ? platformSelected.name
          : platformSelected.full_account_name,
      displayName:
        platformSelected.provider_name === MONEYMADE_MANUAL_ACCOUNT
          ? platformSelected.full_account_name
          : getPlatformIdsCount(portfolioPlatforms, platformSelected?.platform_id, platformSelected?.account_type) === 1
          ? platformSelected.name
          : platformSelected.provider_account_name,
      balance: platformSelected?.balance,
      balances: platformSelected?.balances || [],
      connector: platformSelected?.connector || '',
      connected: platformSelected?.connected || false,
      connected_at: platformSelected?.connected_at || null,
      additional_data: platformSelected?.additional_data || null,
      provider_account_type: platformSelected?.provider_account_type || null,
      updated_at: platformSelected?.updated_at || null,
      provider_id: platformSelected?.provider_id || '',
      portfolio_id: platformSelected?.portfolio_id || '',
      connect_url: platformSelected?.connect_url || null,
      oauth_strategy: platformSelected?.oauth_strategy || null,
      oauth_origin: platformSelected?.oauth_origin || null,
      account_id: platformSelected?.account_id || null,
      manual: platformSelected?.connector === 'manual',
      id: platformSelected?.platform_id || '',
      platform_id: platformSelected?.platform_id,
      slug: platformSelected?.slug || '',
      description: platformSelected?.description || '',
      totalInvestments: platformSelected?.portfolio?.total || '',
      averagePortfolioSize: platformSelected?.portfolio?.average || '',
      typeAllocated: platformSelected?.portfolio?.industry_weight || '',
      type: platformSelected?.industry || 'Other investments',
      industry: platformSelected?.industry || 'Other',
      averageRating: platformSelected?.averageRating || '',
      twitter: platformSelected?.twitter || '',
      relatedProducts: platformSelected?.similars
        ? platformSelected.similars.map(similar => investments.find(({ id }) => id === similar))
        : [],
      allDataDifference,
      displayFilter: {
        [DISPLAY_FILTER[0]]: {
          value: toCorrectValue(platformSelected?.balance || 0),
          percentAge: null,
          rate: getRate(platformSelected?.balances)
        },
        [DISPLAY_FILTER[1]]: {
          value: toSignCorrectDisplayValue(toDecimalsCorrect(allDataDifference.value)),
          percentAge: toSignCorrectPercentageValue(toDecimalsCorrect(allDataDifference.value2))
        }
      },
      type_logo:
        platformSelected?.provider_name === MONEYMADE_MANUAL_ACCOUNT
          ? platformSelected?.additional_data?.logo || null
          : null,

      guid: `${platformSelected.provider_name}_${platformSelected.account_type}_${platformSelected?.platform_id}_${platformSelected?.account_id}`,
      parent: false,
      single:
        platformSelected.provider_name === MONEYMADE_MANUAL_ACCOUNT
          ? true
          : getPlatformIdsCount(portfolioPlatforms, platformSelected?.platform_id, platformSelected?.account_type) ===
            1,
      classId: `${platformSelected.account_type || 'NEW'}_${platformSelected.platform_id}`,
      toggleName: `${platformSelected.provider_name} ${platformSelected.account_type}`,
      lastId: isLastId(portfolioPlatforms, index, platformSelected?.platform_id),
      connection: platformSelected?.connection,
      moneyMadeManual: platformSelected.provider_name === MONEYMADE_MANUAL_ACCOUNT,
      ytdData,
      // ytd: ytdData.length > 0 && platformSelected?.balance ? ((platformSelected?.balance - ytdData[0]?.balance) / platformSelected?.balance) * 100 : 0
      ytd: platformSelected?.ytd || 0
    })
  })

  // generate sub-account parent accounts
  const parentAccounts = accounts
    .filter(({ provider_name: providerName }) => providerName !== MONEYMADE_MANUAL_ACCOUNT)
    .filter(({ single }) => !single)
    .reduce((ac, current) => {
      if (
        !ac.some(
          ({ platform_id: platformId, account_type: accountType }) =>
            platformId === current.platform_id && accountType === current.account_type
        )
      )
        ac.push(current)
      return ac
    }, [])
    .map(account => ({
      ...account,
      parent: true,
      guid: `${account.provider_name}_${account.account_type}`,
      full_account_name: account.name,
      balance: toCorrectValue(
        portfolioPlatforms
          .filter(
            ({ platform_id: platformId, account_type: accountType }) =>
              platformId === account.platform_id && accountType === account.account_type
          )
          .reduce((ac, current) => ac + current.balance, 0)
      ),
      classId: `${account.account_type}_${account.platform_id}`,
      single: false,
      lastId: false
    }))

  const allAccounts = [...accounts, ...parentAccounts]
  return allAccounts
}

const isExpirationDateStale = expDate => !(new Date(expDate) - new Date().getTime() >= 0)

// Set a status of the connected account page on the dashboard to redirect only once
const setСonnectedAccountStatus = status =>
  isLocalStorage() && localStorage.setItem('dashboardConnectedAccountVisited', status)

const toShuffle = array => {
  let currentIndex = array.length
  let temporaryValue
  let randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    // @Ruslan
    // eslint-disable-next-line no-param-reassign
    array[currentIndex] = array[randomIndex]
    // @Ruslan
    // eslint-disable-next-line no-param-reassign
    array[randomIndex] = temporaryValue
  }

  return array
}

// Set a toggle status of the parent sub account on the dashboard
const setToggleAccount = (name, status) => {
  if (isLocalStorage()) {
    const currentAccounts = JSON.parse(localStorage.getItem('toggleAccounts'))
    if (currentAccounts) localStorage.setItem('toggleAccounts', JSON.stringify({ ...currentAccounts, [name]: status }))
    else localStorage.setItem('toggleAccounts', JSON.stringify({ [name]: status }))
  }
}

// Get a toggle status of the parent sub account on the dashboard
const getToggleAccount = () => isLocalStorage() && JSON.parse(localStorage.getItem('toggleAccounts'))

// set account and sub account data on the dashboard
const setAccount = (name, key, value) => {
  if (isLocalStorage()) {
    const currentAccounts = JSON.parse(localStorage.getItem('accountsMM'))
    if (currentAccounts)
      localStorage.setItem(
        'accountsMM',
        JSON.stringify({ ...currentAccounts, [name]: { ...currentAccounts[name], [key]: value } })
      )
    else localStorage.setItem('accountsMM', JSON.stringify({ [name]: { [key]: value } }))
  }
}

// get account and sub account data on the dashboard
const getAccount = () => isLocalStorage() && (JSON.parse(localStorage.getItem('accountsMM')) || {})

const getFilterQuiz = (data, riskValue, cashValue, timeframeValue, accreditedValue) => {
  let filterredList = data

  // Risk
  filterredList = riskValue?.length
    ? riskValue.reduce((acc, value) => [...acc, ...filterredList.filter(({ newRisks }) => newRisks[0] === value)], [])
    : filterredList

  // Cash Payments
  filterredList = cashValue?.length
    ? cashValue.reduce((acc, value) => {
        switch (value) {
          case '1':
            return [...acc, ...filterredList.filter(({ objectives }) => objectives.includes('Passive Income'))]

          case '2':
            return [...acc, ...filterredList.filter(({ objectives }) => objectives.includes('Long Term Growth'))]

          case '3':
            return [...acc, ...filterredList.filter(({ objectives }) => objectives.includes('Balanced Investing'))]

          default:
            return acc
        }
      }, [])
    : filterredList

  // Timeframe
  filterredList = timeframeValue?.length
    ? timeframeValue.reduce(
        (acc, value) => [...acc, ...filterredList.filter(({ timeframeNumber }) => timeframeNumber <= Number(value))],
        []
      )
    : filterredList

  // Accredited
  if (!accreditedValue.includes('1')) filterredList = filterredList.filter(({ investors }) => investors === 'All')

  // Remove dublicates from array with objects
  filterredList = [...new Map(filterredList.map(inv => [inv.id, inv])).values()]
  return filterredList
}

const getOpenedWindow = (url, width = 500, height = 500) => {
  const windowOptions = 'scrollbars=yes,resizable=yes,toolbar=no,location=yes'
  // @Ruslan
  // eslint-disable-next-line no-restricted-globals
  const winHeight = screen.height
  // @Ruslan
  // eslint-disable-next-line no-restricted-globals
  const winWidth = screen.width
  const left = Math.round(winWidth / 2 - width / 2)
  let top = 0

  if (winHeight > height) top = Math.round(winHeight / 2 - height / 2)

  return window.open(url, 'intent', `${windowOptions},width=${width},height=${height},left=${left},top=${top}`)
}

const getAverage = (min, max) => max - min

const getAverageMin = data => getMin(data) - getAverage(getMin(data), getMax(data)) * 0.3

const getAverageMax = data => getMax(data) + getAverage(getMin(data), getMax(data)) * 0.3

const getDashboardQuizResult = (answers, investments) => {
  // get results from answers
  const { amount, cash, risk, timeframe, accredited } = answers
  // Get filtered investments base on minInvestemnt

  const filteredByAmount = [
    ...investments.filter(({ premium }) => premium),
    ...investments.filter(({ premium, paid }) => paid && !premium),
    ...investments.filter(({ premium, paid }) => !paid && !premium)
  ].filter(({ minInvestment }) =>
    amount[0] === 10000 ? minInvestment >= 1000 : minInvestment <= parseInt(amount[0], 10)
  )
  // Get filtered investments base on the answers
  const filteredInvestments = getFilterQuiz(filteredByAmount, risk, cash, timeframe, accredited)
  const limitedInvestments = toLimitedSort(filteredInvestments)
  // return max 3 items
  return limitedInvestments.slice(0, 3)
}

const sortDesc = (items, key) => items.sort((a, b) => a[key] - b[key])

// // Get White List for auth
// const getStatusWhiteList = () => {
//   const whiteList = false

//   if (!isLocalStorage()) return whiteList

//   const serializedState = localStorage.getItem('whiteList')

//   return Boolean(serializedState) || whiteList
// }

// // Set White List after auth
// const setStatusWhiteList = whiteList => isLocalStorage() && localStorage.setItem('whiteList', whiteList)

const getButtonName = (data, connected) => {
  if (!data && !connected) return 'Connect'
  if (data && !connected) return 'Reconnect'
  if (!data && connected) return 'Pending'
  return 'Connected'
}

const isUpdatedAtEqual = (guid, updatedAt, providerName) => {
  // equal for OTHER
  if (providerName === MONEYMADE_MANUAL_ACCOUNT) return true
  // get data from LS
  const accountLocal = getAccount()
  // if no data set equal
  if (!accountLocal[guid]) return false
  // compare time from LS with new data
  return accountLocal[guid]?.updatedAt?.toString() === updatedAt?.toString()
}

const getLastUpdatedTimeDifference = updatedAt => {
  // skip if no exist
  if (!updatedAt) return ''
  // get current date
  const currentDate = new Date()
  // get last updated date
  const updatedAtDate = new Date(updatedAt) /* new Date('Jun 01, 2021 13:15:30')  */
  // get hourse difference between current and last updated time
  const hoursDifference = (currentDate - updatedAtDate) / 1000 / 60 / 60
  // set correct data displaying
  const timeDisplaying = toLastUpdatedTimeDisplaying(hoursDifference, currentDate, updatedAtDate)
  return timeDisplaying
}

const toInitialData = data => {
  const toggleAccounts = getToggleAccount()
  return data
    .filter(({ parent }) => parent)
    .map(({ toggleName }) => ({ toggleName }))
    .reduce((ac, cur) => {
      const accumulator = ac
      accumulator[cur.toggleName] = (toggleAccounts && toggleAccounts[`${cur.toggleName}`]) || false
      return accumulator
    }, {})
}

const isBalanceEqual = (guid, balance, providerName) => {
  // equal for OTHER
  if (providerName === MONEYMADE_MANUAL_ACCOUNT) return true
  // get data from LS
  const accountLocal = getAccount()
  // if no data set equal
  if (!accountLocal[guid]) return false
  // compare balance from LS with new data
  return accountLocal[guid]?.balance?.toString() === balance?.toString()
}

const getTags = portfolioData =>
  portfolioData
    .filter(({ type }) => type && type !== 'Other investments')
    .reduce((ac, temp) => {
      const { type } = temp
      const accumulator = ac
      accumulator.push(type)
      return accumulator
    }, [])

const findOne = (haystack, arr) => arr.some(v => haystack.includes(v))

const isPositive = value => value.indexOf('+') === 0

const isZero = value => value.indexOf('0') === 0

const getMyMoneyButtonName = (data, connected) => {
  if (!data && !connected) return 'Connect'
  if (data && !connected) return 'Relink'
  if (!data && connected) return 'Pending'
  return 'Connected'
}

const isStale = updatedAt => {
  // skip if no exist
  if (!updatedAt) return false
  const currentDate = new Date()
  // get last updated date
  const updatedAtDate = new Date(updatedAt)
  // get hourse difference between current and last updated time
  const hoursDifference = (currentDate - updatedAtDate) / 1000 / 60 / 60 / 24
  return hoursDifference >= 14
}

const toCorrectValueCentsFixed = value => {
  if (value.toFixed(2) === '0.00') return `$${value?.toFixed(7) || 0}`
  return `$${toMoneyFormat(value?.toFixed(2) || 0)}`
}

const isPositiveDigit = value => value >= 0

const toInitialDataAnchorsWatchList = data => data.map(({ id }) => ({ [id]: null }))

const toDigit = value => (typeof value === 'number' ? value : +(+value).toFixed(2) || 0)

const toSmallDigit = value => (typeof value === 'number' ? value : +(+value).toFixed(7))

const getExchangeName = exchange => (exchange === 'moneymade-crypto' ? `Crypto` : exchange)

const handleTidio = () => {
  window?.tidioChatApi?.show()
  window?.tidioChatApi?.open()
  window?.tidioChatApi?.messageFromOperator(
    "Something went wrong when connecting your account. We're working on this and will have it resolved soon."
  )
  window?.tidioChatApi?.messageFromOperator('Drop your email below if you want to be notified when this back online.')
}

const toTwitterNudge = portfolioData => {
  const textStart = `https://twitter.com/intent/tweet?text=Hey,%20`
  const textMiddle = `,%20I want to track my `
  const textEnd = ` on @MoneyMade. Can you connect with them to make this happen? https://giphy.com/gifs/tmnyixSd26dTwDbeH9`

  const items = portfolioData
    .filter(({ connector, twitter }) => connector === 'manual' && twitter)
    .map(({ name, twitter }) => ({
      name,
      twitter: `@${twitter}`
    }))

  if (items.length === 1) return `${textStart}${items[0].twitter}${textMiddle}${items[0]?.name}${textEnd}`

  const result = items.reduce((accumulator, { twitter }, index) => {
    const acc = `${accumulator}${index === items.length - 1 ? ` and ` : index === 0 ? '' : `, `}${twitter}`
    return acc
  }, '')
  return `${textStart}${result}${textMiddle}portfolio${textEnd}`
}

const toInitialTypeData = data => {
  const toggleAccounts = getToggleAccount()
  return data
    .map(({ title }) => ({ toggleName: title }))
    .reduce((ac, cur) => {
      const accumulator = ac
      accumulator[cur.toggleName] = (toggleAccounts && toggleAccounts[`${cur.toggleName}`]) || false
      return accumulator
    }, {})
}

const isConnected = (data, connected) => getMyMoneyButtonName(data, connected) === 'Connected'

const isManual = connector => connector === 'manual'

const getRequierdFormKeys = form =>
  Object.entries(form).reduce((ac, [key, value]) => {
    const accumulator = ac
    if (value.required) accumulator[key] = value.required
    return accumulator
  }, {})

const toInitFormValues = keys =>
  keys.reduce((ac, key) => {
    const accumulator = ac
    accumulator[key] = ''
    return accumulator
  }, {})

const toMoneyFormatWithCents = value => {
  const valueTrim = value?.trim()

  const valueInteger = parseInt(valueTrim.split('.')[0], 10)

  const valueCents = valueTrim.split('.')[1]

  const displayValue = `${toMoneyFormatLong(valueInteger)}${
    valueCents !== undefined ? `.${valueCents.substring(0, 2)}` : ''
  }`

  return displayValue
}

export {
  isPortfolioResponseSuccess,
  isObjectKeyEmpty,
  toFilterGeneralValue,
  toFilterPlatformValue,
  getTimeDifference,
  getFirstValueFromArray,
  getPercentagePure,
  toActualDataDifference,
  getDateFormatStepWidth,
  getTimeHours,
  getDateFormatDelimeter,
  toNewPlatformItem,
  toLimitDataByDelimiter,
  toDataRawLimited,
  toSignCorrectPercentageValue,
  toObjectWithoutEmptyResult,
  toObjectWithoutProperty,
  toSortedObjectByTime,
  toNotConnectedPlatforms,
  toFillArrayWithEmptyValues,
  toFilteredMMplatforms,
  toInitialDataAnchors,
  toReplaceTimestampProp,
  getTooltipValue,
  getTagsFromArray,
  toFilteredData,
  toPortfolioPlatforms,
  toPercentAge,
  toSortBy,
  toGroupedItems,
  getSumObjectProp,
  getInvestmentsPercentAge,
  isBalancesResponseSuccess,
  getLastValueFromArray,
  getInvestmentsGeneral,
  toCorrectData,
  getInvestmentsMM,
  toLastUpdatedTime,
  getAccounts,
  toFilterbyAccountType,
  toCorrectValue,
  getBalances,
  toCorrectValueNoCents,
  toPortfolioSections,
  getConnectedAccounts,
  toSelectedCheckboxesObject,
  toUpdatedObjectByChecked,
  getAllAccountsInit,
  toManualDataInit,
  getManualDataKeys,
  getRequierdKeys,
  isAllowed,
  toShapeData,
  isFirstAccountAdded,
  getItemFilteredDifference,
  toLastUpdatedTimeDisplaying,
  isExpirationDateStale,
  setСonnectedAccountStatus,
  toShuffle,
  setToggleAccount,
  getToggleAccount,
  setAccount,
  getAccount,
  getOpenedWindow,
  isNegativeNumber,
  getAverageMin,
  getAverageMax,
  getDashboardQuizResult,
  sortDesc,
  // getStatusWhiteList,
  // setStatusWhiteList,
  getButtonName,
  isUpdatedAtEqual,
  getLastUpdatedTimeDifference,
  isBalanceEqual,
  toInitialData,
  getTags,
  findOne,
  toCorrectValueNoCentsYTD,
  isPositive,
  isZero,
  toCorrectValueCents,
  getMyMoneyButtonName,
  isStale,
  toCorrectValueCentsFixed,
  isPositiveDigit,
  toInitialDataAnchorsWatchList,
  toDigit,
  toSmallDigit,
  getExchangeName,
  handleTidio,
  toTwitterNudge,
  toInitialTypeData,
  isConnected,
  isManual,
  getRequierdFormKeys,
  toInitFormValues,
  toMoneyFormatWithCents
}
