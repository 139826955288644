import moment from 'moment'
import { isLocalStorage } from 'utils'

const toValue = val => {
  if (typeof val === 'number') return val.toFixed(0)
  return val.reduceRight((accumulator, cur) => {
    let ac = accumulator
    ac = `${ac + cur.toFixed(0)} `
    return ac
  }, '')
}

const getPredictionData = prediction =>
  Object.entries(prediction).reduce((accumulator, [name]) => {
    const {
      potential_high: potentialHighLocal,
      potential_high_percent: potentialHighPercentLocal,
      potential_low: potentialLowLocal,
      potential_low_percent: potentialLowPercentLocal
    } = prediction[name]

    const targetHigh = 0
    const targetHighPercent = 0
    const targetLow = 0
    const targetLowPercent = 0

    const rangeData = Object.entries(prediction[name].data).map(([key, value], index) => {
      const { projected, projected_low: projectedLow, projected_high: projectedHigh } = value
      return {
        day: index === 0 ? 'Today' : moment(key).format(`${name === '6m' || name === '1y' ? 'MMM' : 'YYYY'}`),
        labelToolTip: moment(key).format('MMM YYYY'),
        date: key,
        riskRange: [projectedLow, projectedHigh],
        risk: projected,
        riskTargetRange: [0, 0],
        riskTarget: 0
      }
    })

    const ac = accumulator
    ac[name] = {
      potentialHigh: potentialHighLocal,
      potentialHighPercent: potentialHighPercentLocal,
      potentialLow: potentialLowLocal,
      potentialLowPercent: potentialLowPercentLocal,
      rangeData,
      firstDate: rangeData[0].date,
      lastDate: rangeData[rangeData.length - 1].date,
      targetHigh,
      targetHighPercent,
      targetLow,
      targetLowPercent
    }
    return ac
  }, {})

const toFilteredGraphData = (filterByValue, dataValue) => {
  const filteredDate = dataValue[filterByValue?.toLowerCase()]
  const { rangeData, firstDate, lastDate } = filteredDate || {}
  return { rangeData, firstDate, lastDate }
}

const toFilteredHighLowData = (filterByValue, dataValue) => {
  const filteredDate = dataValue[filterByValue?.toLowerCase()]
  const {
    potentialHigh,
    potentialHighPercent,
    potentialLow,
    potentialLowPercent,
    targetHigh,
    targetHighPercent,
    targetLow,
    targetLowPercent
  } = filteredDate || {}
  return {
    potentialHigh,
    potentialHighPercent,
    potentialLow,
    potentialLowPercent,
    targetHigh,
    targetHighPercent,
    targetLow,
    targetLowPercent
  }
}

const toFilteredText = filterBy => {
  let text = ''

  switch (filterBy) {
    case '6M':
      text = '6 months'
      break

    case '1Y':
      text = '1 year'
      break

    case '5Y':
      text = '5 years'
      break

    case '10Y':
      text = '10 years'
      break

    default:
      text = '5 years'
  }

  return text
}

const getContribution = resultUser =>
  +(
    resultUser?.hub?.onboardingAnswers?.find(({ question }) => question === 'monthlyContribution')?.manualAnswer[0] || 0
  )

const getMontlyContribution = resultUser => resultUser?.keys?.['monthly-contributions']

const getMontlyContributionTotal = resultUser =>
  resultUser?.keys?.['monthly-contributions']?.reduce((total, { value }) => total + value, 0)

const getAnswerNumber = (data, name) => data?.find(({ question }) => question === name)?.answer[0]

const getAnswerLabel = (data, name, number) =>
  data?.find(({ slug }) => slug === name)?.answers?.find(({ id }) => id === number)?.name

const getAnswers = (resultUser, answers) => {
  const result = resultUser?.hub?.onboardingAnswers

  const investingGoalsNumber = getAnswerNumber(result, 'investingGoals')
  const timeHorizonNumber = getAnswerNumber(result, 'timeHorizon')

  const investingGoals = getAnswerLabel(answers, 'investingGoals', investingGoalsNumber)
  const timeHorizon = getAnswerLabel(answers, 'timeHorizon', timeHorizonNumber)

  return {
    investingGoals,
    timeHorizon
  }
}

const getPredictionCategories = prediction => {
  const { low, moderate, high, very_high: veryHigh } = prediction
  return { low, moderate, high, very_high: veryHigh }
}

const getPortfolioPlaforms = predictionCategories => {
  const platformRisks = Object.values(predictionCategories).map(riskValue => {
    const riskIndustries = Object.entries(riskValue)
      .filter(([name]) => name !== 'portfolio_net_worth_part')
      .map(([name, value]) => {
        const { portfolio_net_worth_part: industryAllocation, platforms } = value
        return { title: name, platforms, industryAllocation: industryAllocation * 100 }
      })
    return riskIndustries
  })

  const allPlatforms = platformRisks
    ?.flat()
    ?.reduce((industries, { platforms }) => [...industries, ...platforms], [])
    ?.map(platform => {
      const { platform: name, subaccount, asset } = platform
      return {
        ...platform,
        slug: `${name}-${subaccount}`,
        type: asset === 'stock' ? 'stock' : asset === 'crypto' ? 'crypto' : 'asset'
      }
    })

  return allPlatforms
}

const setDefaultContributions = (portfolio, contribution, monthlyContribution) => {
  const monthlyContributionLocal = monthlyContribution
  const portfolioLocal = portfolio.map(
    ({ slug, portfolio_net_worth_part: allocation, platform, asset, subaccount, type }) => ({
      slug,
      value: +(allocation * contribution).toFixed(0),
      platform,
      asset,
      subaccount,
      type
    })
  )

  const single = []

  portfolioLocal.forEach(({ slug, value, platform, asset, subaccount, type }) => {
    const monthly = monthlyContributionLocal.find(({ slug: slugLocal }) => slugLocal === slug)
    if (!monthly) single.push({ slug, value, platform, asset, subaccount, type })
  })

  return [...monthlyContributionLocal, ...single]
}

const getTotalContributions = monthlyContribution => monthlyContribution.reduce((total, { value }) => total + value, 0)

const setDefaultTotal = (monthlyContribution, contribution) =>
  monthlyContribution && !!monthlyContribution?.length ? getTotalContributions(monthlyContribution) : contribution

const getPortfolioAnalysisFirstTimeStatus = () => isLocalStorage() && localStorage.getItem('PortfolioAnalysisFirstTime')

const setPortfolioAnalysisFirstTimeStatus = status =>
  isLocalStorage() && localStorage.setItem('PortfolioAnalysisFirstTime', status)

const toMoneyFormatNoCents = value =>
  Number(value)
    ?.toFixed(0)
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || value

const toMoneyFormatLong = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const addSign = val => (val?.indexOf('-') === 0 ? `${val}` : val?.indexOf('0') === 0 ? val : `+${val}`)

const getAxisLabel = value => (value >= 1000 || value < -1000 ? `${(value / 1000).toFixed(0)}k` : `${value.toFixed(0)}`)

const toLabelFormat = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')

export {
  toValue,
  getPredictionData,
  toFilteredGraphData,
  toFilteredHighLowData,
  toFilteredText,
  getContribution,
  getAnswers,
  getPredictionCategories,
  getPortfolioPlaforms,
  getMontlyContribution,
  setDefaultContributions,
  getTotalContributions,
  setDefaultTotal,
  getPortfolioAnalysisFirstTimeStatus,
  setPortfolioAnalysisFirstTimeStatus,
  toMoneyFormatNoCents,
  toMoneyFormatLong,
  addSign,
  getMontlyContributionTotal,
  getAxisLabel,
  toLabelFormat
}
